<style >
	.iconfont.iconfont-bg {
		font-size: 16px;
		font-weight: 500;
		/* color: rgba(255, 255, 255, 0.5); */
		/* float: right;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				transform: rotate(30deg);
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				position: relative;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				right: -20px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				bottom: -10px; */
	}
	.iconfont-bg {
		font-size: 16px;
		font-weight: 500;
		/* color: rgba(255, 255, 255, 0.5); */
		/* float: right;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				transform: rotate(30deg);
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				position: relative;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				right: -20px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				bottom: -10px; */
	}
	.scard {
		background: url(../../assets/border1.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard2 {
		background: url(../../assets/border2.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard3 {
		background: url(../../assets/border3.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 10px;
	}
	.scard5 {
		background: transparent;
		/* background: url(../../assets/border5.png) no-repeat center center; */
		/* background-size: 100% 100%; */
		/* background: transparent; */
		padding: 10px;
	}
	.scard3 .el-table th,
	.scard3 .el-table tr,
	.scard3 td {
		background: transparent;
		border: 0;
		color: #fff;
	}
	.scard3 .el-table--enable-row-hover .el-table__body tr:hover > td {
		background-color: transparent !important;
	}
	.scard3 .el-table::before {
		background: transparent;
	}
	.snumber {
		background: url(../../assets/num2.png) no-repeat center center;
		background-size: 100% auto;
		height: 74px;
		padding-top: 40px;
	}
	.co2 .el-tabs__header {
		width: 110px;
		margin-left: 30px;
		margin-bottom: 0;
	}
	.co2 .el-tabs__item {
		color: #fff;
	}
	.co2 .el-tabs__item.is-active {
		color: #2c6dd2;
	}
</style>
<template>

	<div class="co2" style="background:#020435;width:100%;min-height:100%">
		<el-radio-group v-model="tab" style="margin-left:20px" size="mini">
			<el-radio-button label="1">电厂</el-radio-button>
			<el-radio-button label="2">氧化铝</el-radio-button>
		</el-radio-group>
		<el-container v-if="tab==1">
			<el-aside width="420px" style="padding:15px;">
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 供电排放强度趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{padding:0}" style="border:0;">
					<mechart id="echart0" title="" subtitle="" :style="'height:220px'" :options="echart0_opts" />
				</el-card>
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 供热排放强度趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{padding:0}" style="border:0;">
					<mechart id="echart1" title="" subtitle="" :style="'height:220px'" :options="echart1_opts" />
				</el-card>
			</el-aside>
			<el-main>
				<div style="text-align:center">
					<el-row>
						<el-col :span="3" style="padding-top:14px">
							<img src="../../assets/co2/icon.png" width="40px" style="marign:12px auto">
						</el-col>
						<el-col :span="19">
							<el-row>
								<el-col :span="12">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年碳排放总量(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">992725 </h1>

								</el-col>
								<el-col :span="12">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年碳排放配额(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">1064270 </h1>

								</el-col>
							</el-row>
							<el-row style="margin-top:10px">
								<el-col :span="12">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年供电排放强度(tCO2/MWh)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">0.5779 </h1>

								</el-col>
								<el-col :span="12">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年供热排放强度(tCO2/GJ)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">0.1101 </h1>

								</el-col>
							</el-row>
						</el-col>
					</el-row>

					<img src="../../assets/co2/co2.png" style="width: 600px;margin: 0px auto;" />
				</div>
			</el-main>
			<el-aside width="420px" style="padding:15px">
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 碳排放趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
					<mechart id="echart2" title="" subtitle="" :style="'height:220px'" :options="echart2_opts" />
				</el-card>
			</el-aside>
		</el-container>
		<el-container v-else>
			<el-aside width="420px" style="padding:15px;">
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 碳排放趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
					<mechart id="echart3" title="" subtitle="" :style="'height:220px'" :options="echart3_opts" />
				</el-card>
			</el-aside>
			<el-main>
				<div style="text-align:center">
					<el-row>
						<el-col :span="3" style="padding-top:14px">
							<img src="../../assets/co2/icon.png" width="40px" style="marign:12px auto">
						</el-col>
						<el-col :span="19">
							<el-row>
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年碳排放总量(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">3125016 </h1>

								</el-col>

								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年化石燃料燃烧(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">1097514 </h1>

								</el-col>
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年生产过程排放(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">109619 </h1>

								</el-col>
							</el-row>
							<el-row style="margin-top:10px">
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年净购入电量排放(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">416103 </h1>

								</el-col>
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年净购入热量排放(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">1501780 </h1>

								</el-col>
								<el-col :span="8">
									<h4 style="color:#ffffffaa;font-size:12px;text-align:left;margin:0;margin-bottom:5px;">全年单位排放强度(t)</h4>
									<h1 style="color:#fff;text-align:left;margin:0;font-size:28px;">1.31 </h1>

								</el-col>

							</el-row>
						</el-col>
					</el-row>

					<img src="../../assets/co2/co2.png" style="width: 600px;margin: 0px auto;" />
				</div>
			</el-main>
			<el-aside width="420px" style="padding:15px">
				<el-aside width="420px" style="padding:15px">
					<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400;width:420px"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 分类碳排放趋势 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
					<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
						<mechart id="echart4" title="" subtitle="" :style="'height:300px'" :options="echart4_opts" />
					</el-card>
				</el-aside>

			</el-aside>
		</el-container>
	</div>
</template>
<script>
	import request from "../../plugins/axios";
	import indexmap from "./page/index_map.vue";
	import mechart from "../../components/mechart.vue";
	import mechartpie from "../../components/mechart_pie.vue";
	export default {
		name: "App",
		data() {
			return {
				tab: 1,
				company_id: 1,
				// clientHeight: "", //页面高度
				role: window.localStorage.getItem("role"),
				nickname: window.localStorage.getItem("nickname"),
				head: window.localStorage.getItem("head"),
				echart0_opts: {
					//图例
					legend: {
						//图例
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "top", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "20",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "供电排放强度",
							type: "bar",
							// smooth: true,
							data: ["0.588", "0.538", "0.570", "0.582", "0.560", "0.475"],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#b9ffaa",
										},
										{
											offset: 1,
											color: "#00daf9",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart1_opts: {
					//图例
					legend: {
						//图例
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "top", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "20",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "供热排放强度",
							type: "bar",
							// smooth: true,
							data: [0.107, 0.111, 0.112, 0.111, 0.11, 0.109],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#b9ffaa",
										},
										{
											offset: 1,
											color: "#00daf9",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart2_opts: {
					//图例
					legend: {
						//图例
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "top", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "20",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "碳排放总量",
							type: "bar",
							// smooth: true,
							data: [
								149262.07, 171127.45, 169901.33, 167326.06, 163869.68, 171238.23,
							],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
						{
							name: "碳排放配额",
							type: "line",
							// smooth: true,
							data: [
								160595.13, 182878.64, 178525.94, 176049.22, 178496.27, 187724.38,
							],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#00d0f9",
										},
										{
											offset: 1,
											color: "#0d64f5",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart3_opts: {
					//图例
					legend: {
						//图例
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "top", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "10",
							top: "40",
							bottom: "10",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "碳排放总量",
							type: "bar",
							// smooth: true,
							data: [233937, 264932, 318082, 280036, 238304, 240790],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#b9ffaa",
										},
										{
											offset: 1,
											color: "#00daf9",
										},
									],
									global: false,
								},
							},
						},
					],
				},
				echart4_opts: {
					//图例
					legend: {
						//图例
						type: "plain", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "center", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "bottom", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "0",
							right: "0",
							top: "10",
							bottom: "100",
							containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],

					xAxis: {
						axisLabel: { color: "#ffffff", interval: 0 },
						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							boundaryGap: true,
							alignWithLabel: true,
						},
						data: ["1月", "2月", "3月", "4月", "5月", "6月"],
					},
					yAxis: {
						show: true,
						type: "value",

						axisLine: {
							show: true,
							lineStyle: { color: "#ffffff" },
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: { color: "#ffffff" },
					},
					series: [
						{
							name: "化石燃料排放",
							type: "bar",
							// smooth: true,
							data: [63968, 63714, 71207, 81196, 53200, 61146],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
						{
							name: "生产过程排放",
							type: "bar",
							// smooth: true,
							data: [4727, 11431, 12446, 9846, 4755, 2022],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
						{
							name: "净购入电量排放",
							type: "bar",
							// smooth: true,
							data: [31920, 35120, 38373, 39049, 33331, 37642],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
						{
							name: "净购入热量排放",
							type: "line",
							// smooth: true,
							data: [133322, 154667, 196056, 149945, 147017, 139980],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
						{
							name: "单位排放强度",
							type: "line",
							// smooth: true,
							data: [1.69, 1.37, 1.57, 1.4, 1.2, 1.18],
							barWidth: 8,
							itemStyle: {
								barBorderRadius: [8, 8, 0, 0],
							},
						},
					],
				},
			};
		},

		mounted() {
			// // 获取浏览器可视区域高度
			// this.clientHeight = `${document.documentElement.clientHeight}`; //document.body.clientWidth;
			// window.onresize = function temp() {
			// 	this.clientHeight = `${document.documentElement.clientHeight}`;
			// };
		},
		components: {
			indexmap,
			mechart,
			mechartpie,
		},
		methods: {},
	};
</script>